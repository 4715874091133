@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;

// Grids (choose one)
@include foundation-xy-grid-classes;
// @include foundation-grid;
// @include foundation-flex-grid;

// Generic components
@include foundation-button;
@include foundation-button-group;
@include foundation-close-button;
@include foundation-label;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-switch;
@include foundation-table;
// Basic components
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-card;
@include foundation-dropdown;
@include foundation-pagination;
@include foundation-tooltip;

// Containers
@include foundation-accordion;
@include foundation-media-object;
@include foundation-orbit;
@include foundation-responsive-embed;
@include foundation-tabs;
@include foundation-thumbnail;
// Menu-based containers
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-accordion-menu;
@include foundation-drilldown-menu;
@include foundation-dropdown-menu;

// Layout components
@include foundation-off-canvas;
@include foundation-reveal;
@include foundation-sticky;
@include foundation-title-bar;
@include foundation-top-bar;

// Helpers
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
// @include foundation-prototype-classes;

// Motion UI
@include motion-ui-transitions;
@include motion-ui-animations;

.membres{
	margin-left: 10%;
	margin-right: 10%;
	width: 80%;
}
.icones{
	display: flex;
	flex-direction: row;
	justify-item: center;
	justify-content: center;
	width: 5%;
	height: auto;
	margin-left: 47%;
}
.Bureau{
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	justify-item: space-around;
	flex-wrap: wrap;

}
.home{
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	justify-item: space-around;
}
#mygalerie{
	border: 1px solid;
	max-width: 60%;
	heigth: auto;
	margin-left: 15%;
	margin-right: 15%	
}